import request from '@/utils/request'

// 生产计划单分页列表
export function planListAPI(params) {
  return request({
    url: '/api/system/produce/plan/list',
    method: 'get',
    params
  })
}
// 新增生产计划单
export function planAddAPI(data) {
  return request({
    url: '/api/system/produce/plan/save',
    method: 'post',
    data
  })
}
//获取生产计划单详情
export function planDetailAPI(billId) {
  return request({
    url: '/api/system/produce/plan/getDetail?billId=' + billId,
    method: 'get'
  })
}
// 修改生产计划单
export function planUpdateAPI(data) {
  return request({
    url: '/api/system/produce/plan/update',
    method: 'put',
    data
  })
}
// 更改状态
export function planUpdateStatusAPI(data) {
  return request({
    url: '/api/system/produce/plan/updateStatus',
    method: 'post',
    data
  })
}
// 查询生产计划单商品相关的信息
export function planGetGoodsInfo(data) {
  return request({
    url: '/api/system/produce/plan/getGoodsInfo',
    method: 'post',
    data
  })
}
// 查询向导数据
export function planGetWizardAPI(data) {
  return request({
    url: '/api/system/produce/plan/getWizardData',
    method: 'post',
    data
  })
}
// 查询向导明细数据
export function planGetWizardDetailAPI(data) {
  return request({
    url: '/api/system/produce/plan/getWizardDetailData',
    method: 'post',
    timeout: 30000,
    data
  })
}
// 查询生产计划明细数据
export function findPlanDetailAPI(data) {
  return request({
    url: '/api/system/produce/plan/findPlanDetail',
    method: 'post',
    data
  })
}
// 完工状态
export function finishWorkAPI(data) {
  return request({
    url: '/api/system/produce/plan/finishWork',
    method: 'post',
    data
  })
}
// 查询关联配方后的明细数据   生产领料的引入源单会用
export function findDetailWithBomAPI(billId) {
  return request({
    url: '/api/system/produce/plan/findDetailWithBom?billId=' + billId,
    method: 'get'
  })
}

//   计划单相关单据列表
export function listImportBillInfo(billId) {
  return request({
    url: '/api/system/produce/plan/listImportBillInfo',
    method: 'post',
    data: { billId }
  })
}
