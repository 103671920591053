import request from '@/utils/request'

// 门店订货列表
export function listOrder(query) {
  return request({
    url: '/api/system/shop/bill/order/list',
    method: 'get',
    params: query
  })
}

// 门店订货列表合计
export function listOrderSummary(params) {
  return request({
    url: '/api/system/shop/bill/order/listSummary',
    method: 'get',
    params
  })
}

//获取门店订货详情
export function getOrderDetail(billId) {
  return request({
    url: '/api/system/shop/bill/order/getDetail',
    method: 'get',
    params: { billId }
  })
}

// 添加门店订货
export function addOrder(data) {
  return request({
    url: '/api/system/shop/bill/order/save',
    method: 'post',
    data: data
  })
}

// 修改门店订货
export function updateOrder(data) {
  return request({
    url: '/api/system/shop/bill/order/update',
    method: 'put',
    data: data
  })
}

// 修改审核状态、关闭、删除
export function updateOrderStatus(data) {
  return request({
    url: '/api/system/shop/bill/order/updateBillStatus',
    method: 'POST',
    data
  })
}

// 复制门店订货
export function copyShopBillOrder(data) {
  return request({
    url: '/api/system/shop/bill/order/copyShopBillOrder',
    method: 'POST',
    data
  })
}
