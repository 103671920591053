import request from '@/utils/request'

//车间列表
export function listWorkshop(params) {
  return request({
    url: '/api/system/mes/workshop/listWorkshop',
    method: 'get',
    params
  })
}

//添加车间
export function addWorkshopReturnResp(data) {
  return request({
    url: '/api/system/mes/workshop/addWorkshopReturnResp',
    method: 'post',
    data
  })
}

//车间信息
export function getWorkshopByWorkshopId(workshopId) {
  return request({
    url: '/api/system/mes/workshop/getWorkshopByWorkshopId?workshopId=' + workshopId,
    method: 'get'
  })
}

//修改车间
export function modifyWorkshop(data) {
  return request({
    url: '/api/system/mes/workshop/modifyWorkshop',
    method: 'post',
    data
  })
}

//删除车间
export function delWorkshop(data) {
  return request({
    url: '/api/system/mes/workshop/delWorkshop',
    method: 'post',
    data
  })
}

//恢复车间
export function revertWorkshop(data) {
  return request({
    url: '/api/system/mes/workshop/revertWorkshop',
    method: 'post',
    data
  })
}