//表格列的写入和读取
import request from '@/utils/request'


//获取UUID
export const getGridUuid = (params) => request({ url: `/api/system/saas/tenant/grid/config/getGridUuid`, method: 'GET', params })

//获取表格配置详情
export const getColumnConfigDetail = (params) => request({ url: `/api/system/saas/tenant/grid/config/getDetail`, method: 'GET', params })

//保存表格配置详情
export const saveColumnConfig = (data) => request({ url: `/api/system/saas/tenant/grid/config/save`, method: 'POST', data })



