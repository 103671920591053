import request from '@/utils/request'

const urlPrefix = ''
const urlAddress = {
  saleOutList: urlPrefix + '/api/system/sale/outbound/list', // 销售出库单
  saleReturnList: urlPrefix + '/api/system/sale/return/list' // 销售退货单
}

// 销售订单列表
export function listBill(query) {
  return request({
    url: '/api/system/sale/bill/list',
    method: 'get',
    params: query
  })
}
// 门店销售订单列表
export function shopSaleList(query) {
  return request({
    url: '/api/system/shop/bill/shopSale/list',
    method: 'get',
    params: query
  })
}

// 门店销售订单列表  合计
export function shopSaleListSummary(params) {
  return request({
    url: '/api/system/shop/bill/shopSale/listSummary',
    method: 'get',
    params
  })
}

//新增销售订单单据
export function addOrder(data) {
  return request({
    url: '/api/system/sale/bill/save',
    method: 'POST',
    data
  })
}

//获取销售订单详情
export function getDetailBill(billId) {
  return request({
    url: `/api/system/sale/bill/getDetail?billId=${billId}`
  })
}

//修改销售订单单据
export function updateOrder(data) {
  return request({
    url: '/api/system/sale/bill/update',
    method: 'PUT',
    data
  })
}

//删除销售订单单据
export function removeOrder(billIds) {
  return request({
    url: '/api/system/sale/bill/remove',
    method: 'delete',
    data: billIds
  })
}

//审核/反审核采购订单
export function updateOrderStatus(billIds, billStatus) {
  return request({
    url: `/api/system/sale/bill/updateBillStatus`,
    method: 'POST',
    data: {
      billIds,
      billStatus
    }
  })
}

//终止销售订单
export function closeOrder(billIds) {
  return request({
    url: '/api/system/sale/bill/closeBillOrder',
    method: 'PUT',
    data: billIds
  })
}

export function httpSaleOutList(params) {
  return request({
    url: urlAddress.saleOutList,
    method: 'get',
    params: params
  })
}

export function httpSaleReturnList(params) {
  return request({
    url: urlAddress.saleReturnList,
    method: 'get',
    params: params
  })
}
// 门店销售单分页列表
export function billSaleOutList(params) {
  return request({
    url: 'api/system/shop/bill/sale/list',
    method: 'get',
    params: params
  })
}

// 门店销售单分页列表  合计
export function billSaleOutListSummary(params) {
  return request({
    url: 'api/system/shop/bill/sale/listSummary',
    method: 'get',
    params: params
  })
}


// 复制门店销售单
export function copyShopSale(data) {
  return request({
    url: '/api/system/shop/bill/sale/copyShopSale',
    method: 'post',
    data
  })
}


// 销售出库单 复制
export function copySaleOutbound(data) {
  return request({
    url: '/api/system/sale/outbound/copy',
    method: 'post',
    data
  })
}

// 销售出库单 汇总
export function sumSaleOutbound(params) {
  return request({
    url: '/api/system/sale/outbound/sumList',
    method: 'get',
    params
  })
}