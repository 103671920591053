import request from '@/utils/request'

//工序任务列表
export function listProcessTask(params) {
  return request({
    url: '/api/system/mes/bill/process/task/listProcessTask',
    method: 'get',
    params
  })
}

//添加工序任务
export function addProcessTask(data) {
  return request({
    url: '/api/system/mes/bill/process/task/addProcessTask',
    method: 'post',
    data
  })
}

//工序任务信息
export function getProcessManByProcessManId(billId) {
  return request({
    url: '/api/system/mes/bill/process/task/getProcessTaskInfo?billId=' + billId,
    method: 'get'
  })
}

//修改工序任务
export function modifyProcessTask(data) {
  return request({
    url: '/api/system/mes/bill/process/task/modifyProcessTask',
    method: 'post',
    data
  })
}

//修改工序任务状态
export function modifyBillStatus(data) {
  return request({
    url: '/api/system/mes/bill/process/task/modifyBillStatus',
    method: 'post',
    data
  })
}
