import request from '@/utils/request'

//收货列表
export function listProcessReceive(params) {
  return request({
    url: '/api/system/mes/bill/process/receive/listProcessReceive',
    method: 'get',
    params
  })
}

//添加收货
export function addProcessReceive(data) {
  return request({
    url: '/api/system/mes/bill/process/receive/addProcessReceive',
    method: 'post',
    data
  })
}

//收货信息
export function getProcessReceiveInfo(billId) {
  return request({
    url: '/api/system/mes/bill/process/receive/getProcessReceiveInfo?billId=' + billId,
    method: 'get'
  })
}

//修改收货
export function modifyProcessReceive(data) {
  return request({
    url: '/api/system/mes/bill/process/receive/modifyProcessReceive',
    method: 'post',
    data
  })
}

//修改收货状态
export function modifyBillStatus(data) {
  return request({
    url: '/api/system/mes/bill/process/receive/modifyBillStatus',
    method: 'post',
    data
  })
}

//选择源单--计划单明细列表
export function listCandidateGoods(data) {
  return request({
    url: '/api/system/mes/bill/process/receive/listCandidateGoods',
    method: 'get',
    data
  })
}
