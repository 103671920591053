import request from '@/utils/request'

//工序领料列表
export function listPickingInfo(params) {
  return request({
    url: '/api/system/mes/bill/process/picking/listPickingInfo',
    method: 'get',
    params
  })
}

//工序领料列表  合计
export function sumListPickingInfo(params) {
  return request({
    url: '/api/system/mes/bill/process/picking/sumListPickingInfo',
    method: 'get',
    params
  })
}

//添加工序领料
export function addPickingBill(data) {
  return request({
    url: '/api/system/mes/bill/process/picking/addPickingBill',
    method: 'post',
    data
  })
}

//工序领料信息
export function getPickingInfo(billId) {
  return request({
    url: '/api/system/mes/bill/process/picking/getPickingInfo?billId=' + billId,
    method: 'get'
  })
}

//修改工序领料
export function modifyPickingBill(data) {
  return request({
    url: '/api/system/mes/bill/process/picking/modifyPickingBill',
    method: 'post',
    data
  })
}

//修改工序领料状态
export function modifyBillStatus(data) {
  return request({
    url: '/api/system/mes/bill/process/picking/modifyBillStatus',
    method: 'post',
    data
  })
}

//工序领料源单列表
export function listImportSourceBill(params) {
  return request({
    url: '/api/system/mes/bill/process/picking/listImportSourceBill',
    method: 'get',
    params
  })
}

//获取源单的领料明细
export function listPickingDetailFromSourceBill(params) {
  return request({
    url: '/api/system/mes/bill/process/picking/listPickingDetailFromSourceBill',
    method: 'get',
    params
  })
}