import request from '@/utils/request'

//平台商户微信App授权分页列表
export function wxAppList(params) {
    return request({
      url: '/api/system/tenant/wxApp/list',
      params
    })
  }

  //删除平台商户微信App授权
export function delWXApp(wxLineIds) {
    return request({
      url: '/api/system/tenant/wxApp/remove',
      method:'delete',
      data:wxLineIds
    })
  }

//上传代码
export function uploadCode(data) {
    return request({
      url: '/api/system/applet/commit',
      method:'post',
      data
    })
  }

//获取小程序体验码
export function TrialQRCode(params) {
  return request({
    url: '/api/system/applet/getTrialQRCode',
    params
  })
}

//提交代码审核
export function submitCodeAudit(data) {
  return request({
    url: '/api/system/applet/submitAudit',
    method:'post',
    data
  })
}

//查询审核状态
export function queryAuditStatus(data) {
  return request({
    url: '/api/system/applet/getAuditStatus',
    method:'post',
    data
  })
}

//获取小程序正式码
export function OfficialCode(data) {
  return request({
    url: 'api/system/applet/getOfficialCode',
    method:'post',
    data
  })
}

//发布已通过审核的小程序
export function appletRelease(data) {
    return request({
        url: '/api/system/applet/release',
        method:'post',
        data
    })
}

//上传代码
export function appletCommit(data) {
  return request({
      url: '/api/system/applet/commit',
      method:'post',
      data
  })
}
