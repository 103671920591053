import request from '@/utils/request'
//商户资料

// 获取商户员工列表
export function tenantEmployeeList (query) {
  return request({
    url: '/api/system/merchant/employee/list',
    method: 'get',
    params: query
  })
}

// 商户员工重置密码
export function updatePassword (data) {
  return request({
    url: '/api/system/merchant/employee/updatePassword',
    method: 'post',
    data
  })
}

// 商户员工启用禁用
export function isStopEmployee (employeeIds) {
  return request({
    url: '/api/system/merchant/employee/isStop',
    method: 'post',
    data: employeeIds
  })
}

// 商户员工删除
export function removeEmployee (employeeIds) {
  return request({
    url: '/api/system/merchant/employee/remove',
    method: 'DELETE',
    data: employeeIds
  })
}

// 获取商户员工详情
export function getEmployeeDetail (employeeId) {
  return request({
    url: `/api/system/merchant/employee/getDetail?employeeId=${employeeId}`
  })
}
// 修改员工启用状态
export function changeEmployeeStatusAPI (data) {
  return request({
    url: '/api/system/employee/changeStatus',
    method: 'put',
    data
  })
}

// 批量还原
export function restoreReduction(data) {
  return request({
    url: "/api/system/merchant/employee/reduction",
    method: 'post',
    data
  });
}