import request from '@/utils/request'

// 微信第三方平台详情
export function wxComponentDetail() {
    return request({
      url: '/api/system/tenant/app/getDetail',
    })
  }


  // 新增/修改微信第三方平台
export function handlewxComponent(data) {
    return request({
      url: '/api/system/tenant/app/save',
      method: 'post',
      timeout: 300000,
      data
    })
  }

  