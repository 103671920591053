import request from '@/utils/request'

//优惠券领出   分页列表
export function couponReceiveList (params) {
  return request({
    url: '/api/system/marketing/base/coupon/receive/list',
    method: 'get',
    params
  })
}
// 优惠券领出  新增
export function couponReceiveSave (data) {
  return request({
    url: '/api/system/marketing/base/coupon/receive/save',
    method: 'post',
    data
  })
}
//优惠券领出  详情
export function couponReceiveDetail (billId) {
  return request({
    url: '/api/system/marketing/base/coupon/receive/getDetail?billId=' + billId,
    method: 'get'
  })
}
// 优惠券领出   修改
export function couponReceiveUpdate (data) {
  return request({
    url: '/api/system/marketing/base/coupon/receive/update',
    method: 'put',
    data
  })
}


//获取优惠券流水号明细
export function getAvailableMarketingCouponBillReceive (data) {
  return request({
    url: '/api/system/marketing/base/coupon/receive/getAvailableMarketingCouponBillReceive',
    method: 'post',
    data
  })
}

//更新单据状态
export function updateBillStatus(billIds, billStatus) {
  return request({
    url:'/api/system/marketing/base/coupon/receive/updateBillStatus',
    method: 'post',
    data: {
      billIds,
      billStatus
    }
  })
}
