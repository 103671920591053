import request from '@/utils/request'

// 分页列表
export function goodsPackingList(params) {
    return request({
        url: '/api/system/goods/packing/list',
        method: 'get',
        params
    })
}


// 新增
export function goodsPackingSave(data) {
    return request({
        url: '/api/system/goods/packing/save',
        method: 'post',
        data
    })
}
// 修改
export function goodsPackingUpdate(data) {
    return request({
        url: '/api/system/goods/packing/update',
        method: 'put',
        data: data
    })
}


//删除
export function goodsPackingDel(billIds) {
    return request({
        url: '/api/system/goods/packing/remove',
        method: 'delete',
        data:billIds
    })
}

// 回收站还原
export function goodsPackingRevert(packingIds) {
    return request({
        url: '/api/system/goods/packing/revert',
        method: 'post',
        data:packingIds
    })
}
