import request from '@/utils/request'

//工单列表
export function listBillHead(params) {
  return request({
    url: '/api/system/mes/bill/work/order/listBillHead',
    method: 'get',
    params
  })
}

//添加工单
export function addWorkOrder(data) {
  return request({
    url: '/api/system/mes/bill/work/order/addWorkOrder',
    method: 'post',
    data
  })
}

//工单信息
export function getProcessManByProcessManId(billId) {
  return request({
    url: '/api/system/mes/bill/work/order/getBillInfoByBillId?billId=' + billId,
    method: 'get'
  })
}

//修改工单
export function modifyWorkOrder(data) {
  return request({
    url: '/api/system/mes/bill/work/order/modifyWorkOrder',
    method: 'post',
    data
  })
}

//修改工单状态
export function modifyStatus(data) {
  return request({
    url: '/api/system/mes/bill/work/order/modifyStatus',
    method: 'post',
    data
  })
}

//选择源单--计划单明细列表
export function listPlanDetailsForSmartDistributeImportSource(data) {
  return request({
    url: '/api/system/produce/plan/listPlanDetailsForSmartDistributeImportSource',
    method: 'post',
    data
  })
}

//生成工序任务
export function generateProcessTask(data) {
  return request({
    url: '/api/system/mes/work/order/process/task/generateProcessTask',
    method: 'post',
    data
  })
}