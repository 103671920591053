import request from '@/utils/request'
// 门店领料列表
export function listOrder (query) {
  return request({
    url: '/api/system/shop/bill/picking/list',
    method: 'get',
    params: query
  })
}

// 门店领料列表  合计
export function listOrderSummary (query) {
  return request({
    url: '/api/system/shop/bill/picking/listSummary',
    method: 'get',
    params: query
  })
}

//更新门店领料单据状态
export function updateOrderStatus (data) {
  return request({
    url: '/api/system/shop/bill/picking/updateBillStatus',
    method: 'post',
    data
  })
}
//修改门店领料单据
export function updateOrder (data) {
  return request({
    url: '/api/system/shop/bill/picking/update',
    method: 'PUT',
    data
  })
}

//获取门店领料单据详情
export function getOrderDetail (billId) {
  return request({
    url: `/api/system/shop/bill/picking/getDetail?billId=${billId}`
  })
}

//新增门店领料单据
export function addOrder (data) {
  return request({
    url: '/api/system/shop/bill/picking/save',
    method: 'POST',
    data
  })
}

// 复制门店领料
export function copyShopPicking(data) {
  return request({
    url: '/api/system/shop/bill/picking/copyShopPicking',
    method: 'POST',
    data
  })
}