import request from '@/utils/request'

//优惠券销售   分页列表
export function couponSaleList (params) {
  return request({
    url: '/api/system/marketing/base/coupon/sale/list',
    method: 'get',
    params
  })
}
// 优惠券销售  新增
export function couponSaleSave (data) {
  return request({
    url: '/api/system/marketing/base/coupon/sale/save',
    method: 'post',
    data
  })
}
//优惠券销售  详情
export function couponSaleDetail (billId) {
  return request({
    url: '/api/system/marketing/base/coupon/sale/getDetail?billId=' + billId,
    method: 'get'
  })
}
// 优惠券销售   修改
export function couponSaleUpdate (data) {
  return request({
    url: '/api/system/marketing/base/coupon/sale/update',
    method: 'put',
    data
  })
}


//获取优惠券流水号明细
export function getAvailableMarketingCouponBillSale (data) {
  return request({
    url: '/api/system/marketing/base/coupon/sale/getAvailableMarketingCouponBillSale',
    method: 'post',
    data
  })
}

//更新单据状态
export function updateBillStatus(billIds, billStatus) {
  return request({
    url:'/api/system/marketing/base/coupon/sale/updateBillStatus',
    method: 'post',
    data: {
      billIds,
      billStatus
    }
  })
}
