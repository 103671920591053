import request from '@/utils/request'

//工序退料列表
export function listMaterialReturn(params) {
  return request({
    url: '/api/system/mes/bill/process/material/return/listMaterialReturn',
    method: 'get',
    params
  })
}

//工序退料列表  合计
export function sumListMaterialReturn(params) {
  return request({
    url: '/api/system/mes/bill/process/material/return/sumListMaterialReturn',
    method: 'get',
    params
  })
}

//添加工序退料
export function addMaterialReturnBill(data) {
  return request({
    url: '/api/system/mes/bill/process/material/return/addMaterialReturnBill',
    method: 'post',
    data
  })
}

//工序退料信息
export function getMaterialReturnInfo(billId) {
  return request({
    url: '/api/system/mes/bill/process/material/return/getMaterialReturnInfo?billId=' + billId,
    method: 'get'
  })
}

//修改工序退料
export function modifyMaterialReturnBill(data) {
  return request({
    url: '/api/system/mes/bill/process/material/return/modifyMaterialReturnBill',
    method: 'post',
    data
  })
}

//修改工序退料状态
export function modifyBillStatus(data) {
  return request({
    url: '/api/system/mes/bill/process/material/return/modifyBillStatus',
    method: 'post',
    data
  })
}

//工序退料源单列表
export function listImportSourceBill(params) {
  return request({
    url: '/api/system/mes/bill/process/material/return/listImportSourceBill',
    method: 'get',
    params
  })
}

//获取源单的退料明细
export function listMaterialReturnDetailFromSourceBill(data) {
  return request({
    url: '/api/system/mes/bill/process/material/return/listMaterialReturnDetailFromSourceBill',
    method: 'post',
    data
  })
}