import request from '@/utils/request'

//报工列表
export function listProcessReport(params) {
  return request({
    url: '/api/system/mes/bill/process/report/listProcessReport',
    method: 'get',
    params
  })
}

//添加报工
export function addProcessReport(data) {
  return request({
    url: '/api/system/mes/bill/process/report/addProcessReport',
    method: 'post',
    data
  })
}

//报工信息
export function getProcessReportInfo(billId) {
  return request({
    url: '/api/system/mes/bill/process/report/getProcessReportInfo?billId=' + billId,
    method: 'get'
  })
}

//修改报工
export function modifyProcessReport(data) {
  return request({
    url: '/api/system/mes/bill/process/report/modifyProcessReport',
    method: 'post',
    data
  })
}

//修改报工状态
export function modifyBillStatus(data) {
  return request({
    url: '/api/system/mes/bill/process/report/modifyBillStatus',
    method: 'post',
    data
  })
}

//选择源单--计划单明细列表
export function listCandidateGoods(data) {
  return request({
    url: '/api/system/mes/bill/process/report/listCandidateGoods',
    method: 'get',
    data
  })
}
